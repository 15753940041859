<template>
  <div id="ProductList">
    <FirstCategory />
    <div class="row main">
      <Menu />
      <List />
    </div>
  </div>
</template>

<script>
import FirstCategory from '../components/ProductList/Category/index';
import Menu from '../components/ProductList/Menu';
import List from '../components/ProductList/List';
import { GetMetaData } from '@/common/meta.js';
import { ReturnErrorPage } from '@/common/prerender_event';
export default {
  name: 'ProductList',
  components: {
    FirstCategory,
    Menu,
    List,
  },
  data() {
    return {
      meta_data: null,
    };
  },
  methods: {
    CheckSeriesExist() {
      this.series.length > 0
        ? this.series.filter(
            (item) => item.ProductDirID == this.$route.params.type
          ).length > 0 ||
          this.$route.params.type == 'all' ||
          this.$route.params.type == 'new' ||
          this.$route.params.type == 'hot'
          ? ((this.meta_data = GetMetaData(
              '商品列表',
              '居家五金工具、專業五金工具，你需要的各式五金商品，盡在宏信五金行！',
              ''
            )),
            this.$nextTick(() => {
              window.prerenderReady = true;
            }))
          : ReturnErrorPage()
        : '';
    },
  },
  watch: {
    series() {
      this.CheckSeriesExist();
    },
    $route() {
      this.CheckSeriesExist();
    },
  },
  computed: {
    series() {
      return this.$store.state.series;
    },
  },
  created() {
    this.CheckSeriesExist();
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
