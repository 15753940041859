<template src="./template.html"></template>

<script>
export default {
  name: "ProductListList",
  data() {
    return {
      menu_open: -1,
      menu_active: false
    }
  },
  methods: {
    GetSeries(id) {
      let now_series = this.series.filter(item => { return item.ProductDir2ID == id })
      let count = 0
      now_series.forEach(item => {
        count += this.GetProducts(item.ProductDirID)
      });

      return count <= 0 ? -1 : now_series
    },
    GetProducts(id) {
      let now_products = this.products.filter(item => { return item.ProductDirID == id })
      return now_products.length
    },
    OpenMenu(val) {
      if (val == this.menu_open) {
        this.menu_open = -1
      }
      else {
        this.menu_open = val
      }
    },
    ToggleMenu() {
      this.menu_active = false
      document.getElementById("app").classList.remove("lock")
    },
    LockAppAndOpen(action) {
      action ? document.getElementById("app").classList.add("lock") : document.getElementById("app").classList.remove("lock")
      this.menu_active = action
    }
  },
  created() {
    this.menu_open = this.$route.params.type
  },
  watch: {
    "$route"() {
      this.menu_open = this.$route.params.type
    }
  },
  computed: {
    categories() {
      return this.$store.state.category
    },
    series() {
      return this.$store.state.series
    },
    products() {
      return this.$store.state.products
    }
  }
}
</script>